import React from 'react'
import Input from "../../ui/input"
import SectionHeading from '../common/section-heading'
import Button from '@/app/ui/button'
import { Dialog } from '@headlessui/react'
import { getIcon } from '@/lib/get-icon'
import commonIcons from '../icons/common-icons'

function CareersModal({ isOpen, setIsOpen }: any) {
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4 h-screen bg-black/80">
        <div className='bg-white p-8 relative'>
          <button onClick={() => setIsOpen(false)} className='absolute top-2 right-2'>{getIcon({iconList:commonIcons, iconName:"CloseIcon", stroke:"black", width:"15", height:"15"})}</button>
          <SectionHeading title={"Submit Your Details"} titleClass="pt-0" />
          <form>
            <div className='w-full flex flex-col gap-5'>
              <div className='flex gap-5 w-full'>
                <Input name={'full_Name'} labelKey='Full Name' variant='outline' className='w-full' />
                <Input name={'email_address'} labelKey='Email address' variant='outline' className='w-full' />
              </div>
              <div className='flex gap-5'>
                <Input name={'phone_number'} labelKey='Phone Number' variant='outline' className='w-full gap-2' />
                <Input type='file' name={'upload_resume'} labelKey='Upload Resume' className='w-full' />
              </div>
              <div className='flex justify-center'>
                <Button>Apply</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  )
}

export default CareersModal
